.WizardItem {
  margin-top: 12px;
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background: var(--main-bg-color);
}

/* .WizardItem::after {
  content: "";
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  width: 90%;
  margin-left: auto;
  margin-right: auto;
} */

.WizardItem:first-child {
  margin-top: 0px;
}

.WizardItem__Name::before,
.WizardItem__SlotType::before,
.WizardItem__SlotCost::before,
.WizardItem__Description::before,
.WizardItem__ShiftStrength::before,
.WizardItem__Uses::before,
.WizardItem__ShortNotes::before,
.WizardItem__Bonus::before {
  font-weight: bold;
}

.WizardItem__Name::before {
  content: "name: ";
}

.WizardItem__SlotType::before {
  content: "slot type: ";
}

.WizardItem__SlotCost::before {
  content: "slot cost: ";
}
.WizardItem__Description::before {
  content: "description: ";
}
.WizardItem__ShiftStrength::before {
  content: "shift strength: ";
}
.WizardItem__Uses::before {
  content: "uses: ";
}
.WizardItem__ShortNotes::before {
  content: "short notes: ";
}
.WizardItem__Bonus::before {
  content: "bonus: ";
}
