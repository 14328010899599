.StressBoxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  margin-top: -1rem;
}

.StressBoxes__Box {
  width: 4rem;
  height: 4rem;
  font-size: 3.5rem;
  border: 1px solid black;
  border-radius: 50%;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}

.StressBoxes__Box--full {
  background-color: black;
  color: white;
}
/* 
.StressBoxes__Box--full.StressBoxes__Box--supernatural {
  background-color: orange;
} */

.StressBoxes__Box--empty {
  background-color: white;
}

.StressBoxes__Box--empty.StressBoxes__Box--supernatural {
  background-color: lightgoldenrodyellow;
}

.StressBoxes__Box--pending {
  animation: pulse 0.25s alternate infinite;
}

.StressBoxes__Box--rejected {
  box-shadow: 0 0 10px red;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 blue;
  }

  100% {
    box-shadow: 0 0 10px blue;
  }
}

@media (min-width: 768px) {
  .StressBoxes {
    margin-top: -0.5rem;
  }
  .StressBoxes__Box {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }
}
/* 
@media (min-width: 1024px) {

  .StressBoxes__Box {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
 */
