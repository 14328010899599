:root {
  --main-bg-color: #fcf9ee;
  --ansi-red: #a30000;
  --ansi-yellow: #575700;
  --ansi-green: #005c00;
  --ansi-blue: #000094;
  --ansi-teal: #005c5c;
  --ansi-magenta: #9e009e;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: black;

  background: linear-gradient(
      rgba(255, 255, 255, 0.45),
      rgba(255, 255, 255, 0.45)
    ),
    url("/background.jpg");
  background-size: cover, cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* body { */
/* height: 100%; */
/* max-height: 600px; */
/* width: 100px; */
/* background-image: url("https://78.media.tumblr.com/cae86e76225a25b17332dfc9cf8b1121/tumblr_p7n8kqHMuD1uy4lhuo1_540.png"),
    url("https://78.media.tumblr.com/66445d34fe560351d474af69ef3f2fb0/tumblr_p7n908E1Jb1uy4lhuo1_1280.png"),
    url("https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png"),
    url("https://78.media.tumblr.com/5ecb41b654f4e8878f59445b948ede50/tumblr_p7n8on19cV1uy4lhuo1_1280.png"),
    url("https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png");
  background-repeat: repeat-x;
  background-position: 0 20%, 0 100%, 0 50%, 0 100%, 0 0;
  background-size: 2500px, 800px, 500px 200px, 1000px, 400px 260px;
  animation: 50s para infinite linear; */
/* } */
.clickable {
  cursor: pointer;
}

@keyframes para {
  100% {
    background-position: -5000px 20%, -800px 95%, 500px 50%, 1000px 100%,
      400px 0;
  }
}
