.CharacterSheet {
  font-size: 2rem;
  margin-left: auto;
  margin-right: auto;
  word-wrap: break-word;
}

.CharacterSheet__RefreshLine {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 6px 0;
}

.CharacterSheet__Power {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 6px 0;
}

.CharacterSheet__PowerName {
  font-weight: bold;
}

@media (min-width: 768px) {
  .CharacterSheet__RefreshLine {
    flex-direction: row;
    margin: 0;
  }
  .CharacterSheet {
    font-size: 1rem;
    max-width: 25%;
  }
}
