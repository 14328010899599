.AppHeader {
  margin-top: 1rem;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-wrap: anywhere;
}

.AppHeader__Left {
  font-size: 4rem;
  font-weight: bold;
  color: #902521;
  margin: 0 1rem;
  /* flex: 0.4; */
}

.AppHeader__Right {
  color: #902521;
  font-size: 2rem;
  margin: 0 1rem;
  /* flex: 0.6; */
}

.AppHeader__Auth {
  border: 2px solid #902521;
  margin: 1rem;
  text-align: center;
  max-height: 1.5rem;
  /* flex: 0.1; */
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 600px) {
  .AppHeader {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .AppHeader__Left {
    width: 30%;
  }
  .AppHeader__Right {
    width: 60%;
  }
  .AppHeader__Auth {
    width: 10%;
  }
}
