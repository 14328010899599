.MainMenu {
  display: flex;
  flex-direction: column;
}

.MainMenu > *:first-child {
  margin-top: 6px;
}

.MainMenu > * {
  width: 100%;
  border: 1px solid black;
  box-shadow: 4px 4px black, inset 0 0 0 1000px rgba(255, 255, 255, 0.2);
  padding: 24px;
  background: white;
}

.MainMenu > *:hover {
  background: #aaa;
  box-shadow: 4px 4px black, inset 0 0 0 1000px rgba(255, 255, 255, 0.8);
}

.MainMenu a {
  color: black;
  text-decoration: none;
}

@media (min-width: 600px) {
  .MainMenu > * {
    margin-top: 1rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .MainMenu > * {
    font-size: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .MainMenu > * {
    width: 50%;
  }
}
