.characterSelect__characterStubContainer {
  margin-top: 6px;
}

.characterSelect__characterStubContainer:first-child {
  margin-top: 0px;
}

@media (min-width: 600px) {
  .characterSelect__characterStubContainer {
    margin-top: 1rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  .characterSelect__characterStubContainer {
    width: 50%;
  }
}
