.characterStub {
  background: white;
  color: black;
  width: 100%;
  border: 1px solid black;
  box-shadow: 4px 4px black, inset 0 0 0 1000px rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.characterStub:hover {
  box-shadow: 4px 4px black, inset 0 0 0 1000px rgba(255, 255, 255, 0.8);
}

.characterStub:not(.characterStub--hasImage):hover {
  background: #aaa;
}

.characterStub > * {
  font-size: 2rem;
  margin: 6px;
}

.characterStub__highConcept {
  font-size: 1.5rem;
}

.characterStub__refreshCap--caption,
.characterStub__name--caption,
.characterStub__highConcept--caption {
  font-size: 0.5rem;
}

.characterStub--hasImage {
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

/* .characterStub--hasImage .characterStub__refreshCap--caption,
.characterStub--hasImage .characterStub__name--caption,
.characterStub--hasImage .characterStub__highConcept--caption {
  
} */

@media (min-width: 768px) {
  .characterStub {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
  }
  .characterStub > * {
    font-size: 2.5rem;
  }

  .characterStub__name {
    align-self: stretch;
    flex-grow: 1;
    flex-basis: 80%;
    text-align: left;
  }
  .characterStub__highConcept {
    text-align: left;
    order: 1;
  }
}
