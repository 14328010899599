.Skill {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 6px 0;
}

.Skill:hover::before {
  content: attr(data-description);
  position: absolute;
  padding: 8px 4px;
  vertical-align: center;
  width: 100%;
  left: calc(-100% - 24px);
  background: var(--main-bg-color);
  border: 2px solid black;
  border-radius: 15px;
  transform: translateY(-50%);
  /* border-bottom-right-radius: 15px; */
  /* border-bottom-left-radius: 15px; */
}

.SkillName {
  font-weight: bold;
}

.SkillName,
.SkillLabelLevel {
  text-shadow: 0 0 5px var(--main-bg-color);
}

.SkillName--Epic,
.SkillLabelLevel--Epic {
  color: var(--ansi-magenta);
}

.SkillName--Superb,
.SkillLabelLevel--Superb {
  color: var(--ansi-teal);
}

.SkillName--Great,
.SkillLabelLevel--Great {
  color: var(--ansi-blue);
}

/* .Skill--Great {
  color: #45de9c;
} */

.SkillName--Good,
.SkillLabelLevel--Good {
  color: var(--ansi-green);
}
/* 
.Skill--Good {
  color: #9bfa24;
} */

.SkillName--Fair,
.SkillLabelLevel--Fair {
  color: var(--ansi-yellow);
}

.SkillName--Average,
.SkillLabelLevel--Average {
  color: var(--ansi-red);
}

@media (max-width: 600px) {
  .SkillName--Epic,
  .SkillName--Superb,
  .SkillName--Great,
  .SkillName--Good,
  .SkillName--Fair,
  .SkillName--Average {
    text-shadow: none;
    color: black;
  }
}

@media (min-width: 768px) {
  .Skill {
    flex-direction: row;
    margin: 12px 0;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
}
