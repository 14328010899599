.Compendium__Main h2 {
    font-size: 1rem;
}

@media (min-width: 600px) {
  .Compendium__Focus {
    display:  flex;
    flex-direction: row;
    justify-content: center;
    min-height: 75vh;
  }

  .Compendium__Focus > * {
    display: flex;
    flex-wrap: wrap;
  }

  .Compendium__Focus > * > * {
    flex: .25 0 1;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .Compendium__Focus h1 {
    width: 100%;
  }

  .Compendium__Main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    text-align: center;
  }
}